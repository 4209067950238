<template>
  <section class="home_page" id="section_home">
    <div v-if="loading" class="d-flex flex-row justify-content-center loading_card">
      <!-- LOADER -->
      <div class="loading_card_spinner">
        <hollow-dots-spinner :animation-duration="1000" :dot-size="35" :dots-num="3" color="var(--primary-color)" />

      </div>
      <!-- END LOADER -->

    </div>
    <div v-else>

      <section class="banner_home">

        <div style="direction:ltr">
          <div dir="ltr" class="slideshow-container" v-if="!loadingSlider&&sliders">
              <carousel
              v-if="sliders.length !=0"
                :per-page="1"
                :mouse-drag="false"
                autoplay
                loop
                :autoplayTimeout=5000
                :autoplayDirection="forward"
                :paginationEnabled="false"
              >
                <slide v-for="(item, idx) in sliders" :key="idx">
                  <img class="banner_image" v-if="item.value!=''" :src="item.image" @click="sliderLink(item.value)" />
                  <img class="banner_image" v-else :src="item.image" />
                </slide>
              </carousel>
              <div v-else > <img src="/assets/image/slide1.jpg" /></div>
        </div>
        
        </div>
        <br>

        <div style="text-align:center">
          <span class="dot"></span>
          <span class="dot"></span>

        </div>

      </section>

      <section>

        <div v-if="this.$store.state.apperance_sections == 1 && sections" class="container"  :class="['fixed-div', { 'is-fixed': isFixed }]">
          <div class="d-flex justify-content-between">
          </div>
          <div>
            <button class="section_but active" id="section0" @click="change_section(0)">{{ $t('all') }}</button>
            <button v-for="(item, idx) in sections" :key="idx" class="section_but" :id="'section' + item.id"
              @click="change_section(item.id)">{{ item.label }}</button>
          </div>

        </div>
      </section>

      <section style="margin-top: 5%;" v-if="this.$store.state.apperance_latest_products == 1">
        <div class="container">
          <div class="d-flex justify-content-between">

            <h5> {{ $t("Latest products") }}</h5>
            <router-link :to="{
              name: 'latestProducts',
              params: { storeName: this.$store.storeName, lang:$store.state.routeLang },
            }" class="color_font view_all">
              {{ $t('viewAll') }}</router-link>
          </div>
          <div class="row">
            <div v-for="(item, idx) in new_products" :key="idx"
              :class="'col-lg-3 col-md-3 col-6 news product_cards_col product_home_0'">
              <product :id="item.id" :name="item.name" :description="item.description" :is_favorit="item.is_favorit"
                :image="item.image" :old_price="item.old_price" :new_price="item.new_price" :productid="item.id"
                :rate="item.rate" :count_view="item.count_views" :is_option="item.is_option" :is_offer="item.is_offer"
                :unit="item.unit" :new="item.is_new" :fetuered="item.is_fetured" :discount="item.discount"
                :qnt="item.qnt" :min_qnt="item.min_qnt" :max_qnt="item.max_qnt" :note="item.note" :range_price="item.range_price">
              </product>
            </div>



          </div>




          <button  v-if="new_page<last_new_page"  class="butt_white  center_align" style="margin-top: 4%;" id="news_more"
            @click="loadMore()"> {{ $t('view more') }}
          </button>

         



        </div>
      </section>
      <section v-if="this.$store.state.apperance_best_saller == 1" style="margin-top: 5%;">
        <div class="container">
          <div class="d-flex justify-content-between">

            <h5>{{ $t("bestSeller") }}</h5>
            <router-link :to="{
              name: 'bestSeller',
              params: { storeName: this.$store.storeName, lang:$store.state.routeLang },
            }" class="color_font view_all">
              {{ $t('viewAll') }}
            </router-link>
          </div>
          <div class="row">
            <div v-for="(item, idx) in sold_products" :key="idx"
              :class="'col-lg-3 col-md-3 col-6 sold_product product_cards_col product_home_0'">
              <product :id="item.id" :name="item.name" :description="item.description" :is_favorit="item.is_favorit"
                :image="item.image" :old_price="item.old_price" :new_price="item.new_price" :productid="item.id"
                :rate="item.rate" :count_view="item.count_views" :is_option="item.is_option" :is_offer="item.is_offer"
                :unit="item.unit" :new="item.is_new" :fetuered="item.is_fetured" :discount="item.discount"
                :qnt="item.qnt" :min_qnt="item.min_qnt" :max_qnt="item.max_qnt" :note="item.note" :range_price="item.range_price">
              </product>
            </div>

          </div>
          <button  v-if="saller_page<last_saller_page" class="butt_white center_align" style="margin-top: 4%;"
            id="sold_product_more" @click="loadMoreSaller()"> {{ $t('view more') }}
          </button>

        
        </div>
      </section>

      <section v-if="this.$store.state.apperance_fetured_products == 1 && feautred_products.length != 0"
        style="margin-top: 5%;">
        <div class="container">
          <div class="d-flex justify-content-between">

            <h5> {{ $t('fetured products') }}</h5>
            <router-link :to="{
              name: 'FeaturedProducts',
              params: { storeName: this.$store.storeName, lang:$store.state.routeLang },
            }" class="color_font view_all">
              {{ $t('viewAll') }}
            </router-link>
          </div>
          <div class="row">
            <div v-for="(item, idx) in feautred_products" :key="idx"
              :class="'col-lg-3 col-md-3 col-6 feautred product_cards_col product_home_0'">
              <product :id="item.id" :name="item.name" :description="item.description" :is_favorit="item.is_favorit"
                :image="item.image" :old_price="item.old_price" :new_price="item.new_price" :productid="item.id"
                :rate="item.rate" :count_view="item.count_views" :is_option="item.is_option" :is_offer="item.is_offer"
                :unit="item.unit" :new="item.is_new" :fetuered="item.is_fetured" :discount="item.discount"
                :qnt="item.qnt" :min_qnt="item.min_qnt" :max_qnt="item.max_qnt" :note="item.note" :range_price="item.range_price">
              </product>
            </div>

          </div>

          <button v-if="feutered_page<last_feutered_page" class="butt_white center_align" style="margin-top: 4%;"
            id="feautred_more" @click="loadMoreFeatured()"> {{ $t('view more') }}
          </button>

         



        </div>
      </section>
    </div>
  </section>
</template>
<script>
import product from "../components/one_product_list.vue";
import VueSlickCarousel from 'vue-slick-carousel'
import Slick from 'vue-slick';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { Carousel, Slide } from "vue-carousel";
import { HalfCircleSpinner, HollowDotsSpinner, CirclesToRhombusesSpinner } from "epic-spinners";



export default {
  components: {
    product,
    HalfCircleSpinner,
    HollowDotsSpinner,
    CirclesToRhombusesSpinner,
    Slick,
    VueSlickCarousel,
    Carousel,
    Slide,

  },
  data() {
    return {
      new_page:0,
      saller_page:0,
      feutered_page:0,

      last_new_page:1,
      last_saller_page:1,
      last_feutered_page:1,

      
      DBitems: null,
      loading: false,
      langChange: "",
      sort: 'id',
      order: 'desc',
      paginate: 12,
      new_products: [],
      sold_products: [],
      feautred_products_products: [],
      sections: [],
      loadingSections: false,
      slickOptions: {
        "slidesToScroll": 1,
        "autoplay": true,
        "autoplaySpeed": 4000,
        "pauseOnDotsHover": true,
        "pauseOnFocus": true,
        "pauseOnHover": true,
        "touchThreshold": 5,
        autoplay: true,
        slidesToShow: 1,
        speed: 200,
        "arrows": true,
        dots: false,
        "centerMode": true,
        "infinite": true,
        "fade": true,
        "useCSS": true,
        "waitForAnimate": true
        // Any other options that can be got from plugin documentation
      },
      sliders: [],
      array_slide: [],
      loadingSlider: true,
      items: [1, 2],
      settings: {
        "slidesToScroll": 1,
        "autoplay": true,
        "autoplaySpeed": 4500,
        "pauseOnDotsHover": true,
        "pauseOnFocus": true,
        "pauseOnHover": true,
        "touchThreshold": 5,
        "autoplay": true,
        slidesToShow: 1,
        speed: 200,
        "arrows": true,
        dots: false,
        "centerMode": true,
        "infinite": true,
        "fade": true,
        "useCSS": true,
        "waitForAnimate": true,

      },
      section_id:0,
      isFixed:false,

    };
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  created() {
    if (this.$route.params.lang && this.$route.params.lang != this.$i18n.locale) {
      this.$i18n.locale = this.$route.params.lang; this.$store.state.UnitPrice = this.$store.state.unitAR
      if (this.$i18n.locale == 'en') {
        document.documentElement.setAttribute("dir", "ltr");
        let h = document.querySelector('body');
      }
    }
    ////////////////////////////////////////////////////////
    this.loadingSlider = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sliders",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.sliders = response.data.data.sliders;


      })
      .then(() => {
        this.loadingSlider = false;

      });



    this.loading = true;

    //////////////////////offers//////////////////////
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/theme10/home/sections",

        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }

      )
      .then((response) => {
        this.sections = response.data.data;
        this.loading = false

      })

    ////////////////////////////////////////////////////
    //////////////////////sections//////////////////////
    if (this.$store.state.apperance_sections == 1)
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/theme10/home/section/0/v1",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),

            },
          }
        )
        .then((response) => {
          this.new_products = response.data.data.new_products;
          this.sold_products = response.data.data.sold_products;
          this.feautred_products = response.data.data.feautred_products;
        })
        .then(() => (this.loading = false));



  },
  watch: {
    langChange() {
      this.loading=true;
         this.section_id=0;
         this.new_page=0;
         this.last_new_page=1;

         this.saller_page=0;
         this.saller_new_page=1;

         this.featured_page=0;
         this.last_featured_page=1;
      /////////////////////////////////
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/theme10/home/sections",

          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            }
          }

        )
        .then((response) => {
          this.sections = response.data.data;
          this.loading=false

        })
      //.then(() => (this.loading = false));
      /////////////////////////////////////
      //this.loading= true;
      if (this.$store.state.apperance_sections == 1)
        this.axios
          .get(
            this.$store.state.base_api_url + this.$store.storeName + "/theme10/home/section/0/v1",
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),

              },
            }
          )
          .then((response) => {
            this.new_products = response.data.data.new_products;
            this.sold_products = response.data.data.sold_products;
            this.feautred_products = response.data.data.feautred_products;

          })
      //.then(() => (this.loading = false));

      //////////////////////offers//////////////////////
      this.loadingSlider = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/sliders",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.sliders = response.data.data.sliders;


        })
        .then(() => {
          this.loadingSlider = false;

        });
    },
  },
  updated() {
    this.langChange = this.$i18n.locale
    this.langChange2 = this.$i18n.locale

    //////////////////////sections//////////////////////

    ////////////////////////////////////////////////////


  },
  methods: {
    handleScroll() {
      this.isFixed = window.scrollY > 50; // يتحقق من موضع التمرير
    },
    sliderLink(slidLink){
      window.location.href= slidLink
    },
    change_section(id) {
      this.section_id=id
      this.new_page=0;
         this.last_new_page=1;

         this.saller_page=0;
         this.saller_new_page=1;

         this.featured_page=0;
         this.last_featured_page=1;

      //remove active from butttons
      var list = document.getElementsByClassName('section_but');
      for (let i = 0; i < list.length; i++) {
        list[i].classList.remove('active')
      }
      //add active class
      document.getElementById('section' + id).classList.add('active');

      //////////////////////sections//////////////////////
      this.loadingSections = true;
      if (this.$store.state.apperance_sections == 1)
        this.axios
          .get(
            this.$store.state.base_api_url + this.$store.storeName + "/theme10/home/section/" + id+'/v1',
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),

              },
            }
          )
          .then((response) => {
            this.new_products = response.data.data.new_products;
            console.log('xxxxxxxx', this.new_products)
            this.sold_products = response.data.data.sold_products;
            this.feautred_products = response.data.data.feautred_products;

          })
          .then(() => (this.loadingSections = false));
      //////////////////////offers//////////////////////

    },
    loadMore(){
      if(this.new_page==0){
        this.new_products=[];
      }
      this.new_page++
const params = this.new_page;
this.axios
  .get(
    this.$store.state.base_api_url + this.$store.storeName + "/theme10/home/section/" + this.section_id+'/loadmore/type/new?page='+this.new_page,
    params,
    {
      headers: {
        "Accept-Language": this.$i18n.locale,
        Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
      },
    }
  )
  .then((response) => {
   
    this.new_products.push(...response.data.data);
    this.last_new_page=response.data.meta.last_page;
  })
    },

    loadMoreSaller(){
      if(this.saller_page==0){
        this.sold_products=[];
      }
      this.saller_page++
const params = this.saller_page;
this.axios
  .get(
    this.$store.state.base_api_url + this.$store.storeName + "/theme10/home/section/" + this.section_id+'/loadmore/type/best_saller?page='+this.saller_page,
    params,
    {
      headers: {
        "Accept-Language": 'ar',
        Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
      },
    }
  )
  .then((response) => {
   
    this.sold_products.push(...response.data.data);

    this.last_saller_page=response.data.meta.last_page;
  })
    },

    loadMoreFeatured(){
      if(this.featured_page==0){
        this.feautred_products=[];
      }
      this.feutered_page++
const params = this.feutered_page;
this.axios
  .get(
    this.$store.state.base_api_url + this.$store.storeName + "/theme10/home/section/" + this.section_id+'/loadmore/type/feautred?page='+this.feutred_page,
    params,
    {
      headers: {
        "Accept-Language": 'ar',
        Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
      },
    }
  )
  .then((response) => {
   
    this.feautred_products.push(...response.data.data);
    this.last_feautred_page=response.data.meta.last_page;
  })
    },

    
  }


};
</script>